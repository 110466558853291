import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  Drawer,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material'
import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  TypingIndicator
} from '@chatscope/chat-ui-kit-react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'

import { useMutation } from 'react-query'
import {
  chatClaude,
  ChatClaudeDto,
  getChatgpt_Multurn_qa,
  uploadpdf
} from '@/@core/services/app.service'
import { formatDate, isWindow } from '@/@core/utils/utils'
import { Close, ContentCopy, Replay, WindowMaximize } from 'mdi-material-ui'
import { copyToClipboard } from '@/@core/layouts/utils'
import PositionedSnackbar from '@/@core/components/message'
import _ from 'lodash'

import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`

enum MessageDirection {
  IN = 'incoming',
  OUT = 'outgoing'
}
interface Msg {
  message: string
  sentTime: string
  sender: string
  direction: MessageDirection
  position: string
  isReplay: boolean
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const ChatClaude = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const [historyMsg, setHistoryMsg] = React.useState<Msg[]>([])
  const messageRef = React.useRef<any>()
  const messageInputRef = React.useRef<any>()
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const [article, setArticle] = React.useState('')
  const [file, setFile] = React.useState<File | null>()
  const [messageInputValue, setMessageInputValue] = React.useState('')
  const [process, setProcess] = React.useState(50)
  const [open, setOpen] = React.useState(false)
  const [load, setLoad] = React.useState(false)
  const [maxSize, setMaxSize] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)

    // setHistoryMsg([])

    setHistoryMsg(getLastTenElements(historyMsg))
  }

  const getLastTenElements = (arr: any[]) => {
    // 计算需要截取的起始索引

    // 如果数组长度小于10，则起始索引为0（即返回整个数组）

    // 否则，为数组长度减去10

    const startIndex = arr.length < 20 ? 0 : arr.length - 20

    // 使用slice方法截取数组

    return arr.slice(startIndex)
  }

  const handleNewUserMessage = async (message: string) => {
    setFile(null)

    setMessageInputValue('')
    let newMessage = message

    if (newMessage.includes('<br/><br/><br/><br/><br/>')) {
      const arr = newMessage.split('<br/><br/><br/><br/><br/>')

      if (arr.length > 1) {
        newMessage = arr[1]
      }
    }

    console.log(newMessage)

    try {
      if (newMessage.includes('解读文献') && (!article || article === '')) {
        messageRef?.current?.handleClick(
          t('请点击输入框左侧按钮上传pdf文献'),
          'info'
        )

        // const newChat = [
        //   ...historyMsg,
        //   {
        //     message: newMessage,
        //     sentTime: formatDate(new Date()) ?? '',
        //     sender: 'user',
        //     direction: MessageDirection.OUT,
        //     position: 'last'
        //   }
        // ]

        // setHistoryMsg(newChat)

        return
      } else if (article) {
        setArticle('')

        messageRef?.current?.handleClick(t('Please wait for a moment'), 'info')
        const newChat = [
          ...historyMsg,
          {
            message: article,
            sentTime: formatDate(new Date()) ?? '',
            sender: 'user',
            direction: MessageDirection.OUT,
            position: 'last',
            isReplay: false
          }
        ]

        setHistoryMsg(newChat)

        const param = {
          messages: {
            messages: newChat
              .filter((item) => item.sender !== 'sys')
              .map((item) => {
                return {
                  role: item.sender,
                  content: `${item.message}`
                }
              })
          },
          his: newChat
        }

        await getChatClaudes(param)

        return
      }

      console.log(`New message incoming! ${newMessage}`, historyMsg)

      messageRef?.current?.handleClick(t('Please wait for a moment'), 'info')
      const newChat = [
        ...historyMsg,
        {
          message: newMessage,
          sentTime: formatDate(new Date()) ?? '',
          sender: 'user',
          direction: MessageDirection.OUT,
          position: 'last',
          isReplay: false
        }
      ]

      setHistoryMsg(newChat)

      const param = {
        messages: {
          messages: newChat
            .filter((item) => item.sender !== 'sys')
            .map((item) => {
              return {
                role: item.sender,
                content: `${item.message}`
              }
            })
        },
        his: newChat
      }

      await getChatClaudes(param)

      // await getChatClaude.mutateAsync(param)
    } catch (error) {
      console.error(error)

      messageRef?.current?.handleClick(
        t('Query failed, please try again'),
        'error'
      )
    }

    // Now send the message throught the backend API
  }

  const uploadPdf = useMutation({
    mutationFn: async (params: { file: FormData; url: string }) =>
      await uploadpdf({
        file: params.file,
        url: params.url
      }),
    onSuccess: (data, params) => {
      if (data) {
        //
      }
    }
  })

  const getChatClaude = useMutation({
    mutationFn: async (data: { messages: ChatClaudeDto; his: Msg[] }) =>
      await chatClaude({
        messages: data.messages.messages
      }),
    onSuccess: (data, params) => {
      if (data) {
        console.log(`New message incoming! ${data}`, params)
        const newChat = [
          ...params.his,
          {
            message: data,
            sentTime: formatDate(new Date()) ?? '',
            sender: 'assistant',
            direction: MessageDirection.IN,
            position: 'last',
            isReplay: false
          }
        ]

        setHistoryMsg(newChat)
      }
    }
  })

  const getChatClaudes = async (data: {
    messages: ChatClaudeDto
    his: Msg[]
  }) => {
    const list = []
    const flag = true

    setLoad(true)

    for await (const result of getChatgpt_Multurn_qa(data.messages)) {
      // console.log('results', chat + result.content)

      list.push(result.content)

      console.log('results', list)

      const dataStr = list
        .map((item) => {
          if (item && item !== '') return item
          else return '\n'
        })
        .join('')

      setLoad(false)

      if (flag) {
        const newChat = [
          ...data.his,
          {
            message: dataStr,
            sentTime: formatDate(new Date()) ?? '',
            sender: 'assistant',
            direction: MessageDirection.IN,
            position: 'last',
            isReplay: false
          }
        ]

        setHistoryMsg(newChat)
      } else if (historyMsg.length) {
        const temp = _.cloneDeep(historyMsg)
        const lastMsg = temp[temp.length - 1]

        lastMsg.message = dataStr

        setHistoryMsg(temp)
      }
    }
  }

  function FacebookCircularProgress(props: CircularProgressProps) {
    return (
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />

        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) =>
              theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round'
            }
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    )
  }

  return (
    <Box>
      <IconButton
        onClick={handleClickOpen}
        sx={{
          backgroundColor: 'blanchedalmond !important'
        }}
        disableTouchRipple
      >
        <Typography>
          <SvgIcon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3041 4H13.6323L20.3282 20.9182H24L17.3041 4ZM6.69589 4L0 20.9182H3.7442L5.11351 17.3653H12.1187L13.488 20.9182H17.2322L10.5363 4H6.69589ZM6.32475 14.2233L8.6161 8.27765L10.9075 14.2233H6.32475Z"
                fill="#181818"
                width={'20px'}
              />
            </svg>
          </SvgIcon>
        </Typography>
      </IconButton>

      <Drawer
        anchor={'right'}
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        hideBackdrop={true}
        sx={{
          '& .MuiPaper-root': {
            height: '90%',
            bottom: 0,
            top: '8%'
          },
          width: 0,
          right: 500
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          GLSofort {t('AI Assistant')}
        </DialogTitle>

        <Stack>
          <IconButton
            aria-label="close"
            onClick={() => {
              setMaxSize(!maxSize)
            }}
            sx={{
              position: 'absolute',
              right: 48,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <WindowMaximize />
          </IconButton>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <DialogContent dividers>
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              width: isWindow() ? (maxSize ? 1500 : 500) : '100%'
            }}
          >
            <MainContainer
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                position: 'relative'
              }}
            >
              <ChatContainer>
                <MessageList
                  typingIndicator={
                    load ? (
                      <TypingIndicator
                        content={t('Question search, please wait.')}
                      />
                    ) : (
                      ''
                    )
                  }
                >
                  {historyMsg
                    .filter((item) => !item.isReplay)
                    .map((item) => {
                      return (
                        <React.Fragment>
                          {item.message.includes('请用中文解读一下文献') ? (
                            <Message
                              model={{
                                message: '解读文献',
                                sentTime: item.sentTime,
                                sender: item.sender,
                                direction: item.direction,
                                position: item.position as 'normal'
                              }}
                              avatarPosition={'top-right'}
                              children={
                                <Avatar
                                  name="Zoe"
                                  src="/images/avatars/3.png"
                                />
                              }
                            />
                          ) : (
                            <Message
                              model={{
                                message: item.message,
                                sentTime: item.sentTime,
                                sender: item.sender,
                                direction: item.direction,
                                position: item.position as 'normal'
                              }}
                              avatarPosition={
                                item.direction === MessageDirection.IN
                                  ? 'top-left'
                                  : 'bottom-right'
                              }
                              children={
                                item.direction === MessageDirection.IN ? (
                                  <Avatar
                                    name="Zoe"
                                    src="/images/avatars/ai.png"
                                  />
                                ) : (
                                  <Avatar
                                    name="Zoe"
                                    src="/images/avatars/3.png"
                                  />
                                )
                              }
                            />
                          )}

                          {item.direction === MessageDirection.IN ? (
                            <Box maxWidth={'85%'} textAlign="right">
                              <Tooltip placement="bottom" title={t('Copy')}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => {
                                    //
                                    try {
                                      if (item.message) {
                                        copyToClipboard(item.message, e)

                                        messageRef?.current?.handleClick(
                                          t('Successful copy'),
                                          'success'
                                        )
                                      }
                                    } catch (error) {
                                      console.log(123, error)
                                    }
                                  }}
                                >
                                  <ContentCopy />
                                </IconButton>
                              </Tooltip>

                              <Tooltip placement="bottom" title={t('重新生成')}>
                                <IconButton
                                  size="small"
                                  onClick={async (e) => {
                                    if (historyMsg && historyMsg.length >= 2) {
                                      const tempList = _.cloneDeep(historyMsg)
                                      const firstLast = tempList.at(-1) as Msg
                                      const secondLast = tempList.at(-2) as Msg

                                      firstLast.isReplay = true

                                      tempList.push({
                                        message: '请重新生成一下',
                                        sentTime: formatDate(new Date()) ?? '',
                                        sender: 'user',
                                        direction: MessageDirection.OUT,
                                        position: 'last',
                                        isReplay: true
                                      })

                                      setHistoryMsg(tempList)
                                      const param = {
                                        messages: {
                                          messages: tempList
                                            .filter(
                                              (item) => item.sender !== 'sys'
                                            )
                                            .map((item) => {
                                              return {
                                                role: item.sender,
                                                content: `${item.message}`
                                              }
                                            })
                                        },
                                        his: tempList
                                      }

                                      await getChatClaudes(param)
                                    }
                                  }}
                                >
                                  <Replay />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      )
                    })}
                </MessageList>

                <MessageInput
                  placeholder={t(
                    '请将您遇到的问题告诉我，使用 Shift + Enter 换行'
                  )}
                  onSend={handleNewUserMessage}
                  onAttachClick={(e) => {
                    inputRef.current?.click()
                  }}
                  ref={messageInputRef}
                  onChange={(
                    innerHtml: string,
                    textContent: string,
                    innerText: string,
                    nodes: NodeList
                  ) => {
                    console.log(innerHtml, textContent, innerText)

                    setMessageInputValue(innerHtml)
                  }}
                  value={messageInputValue}
                />
              </ChatContainer>

              {file ? (
                <Box
                  id={'125'}
                  sx={{
                    position: 'absolute',
                    zIndex: 999,
                    width: '80%',
                    right: 0,
                    left: 0,
                    margin: '0 auto',
                    bottom: '35px'
                  }}
                  maxWidth={300}
                >
                  <Card>
                    <CardContent
                      sx={{
                        p: 2,
                        paddingBottom: '0.5rem !important'
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <Box
                            sx={{
                              bgcolor: 'rgb(255,86,135)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 50,
                              height: 50,
                              borderRadius: '5px'
                            }}
                          >
                            <FacebookCircularProgress
                              value={process}
                              variant={'determinate'}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={9} pl={2}>
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              width: '100%',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {file?.name}
                          </Typography>

                          <Stack
                            direction={'row'}
                            alignItems="center"
                            justifyContent={'space-between'}
                          >
                            <Typography>PDF</Typography>

                            <IconButton
                              onClick={() => {
                                setProcess(0)

                                setArticle('')

                                setFile(null)

                                setMessageInputValue('')
                              }}
                              size="small"
                            >
                              <Close />
                            </IconButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                ''
              )}

              {historyMsg && historyMsg.length ? (
                ''
              ) : (
                <Stack
                  justifyContent={'center'}
                  sx={{
                    left: 0,
                    right: 0,
                    top: '30%',
                    margin: '0 auto'
                  }}
                  zIndex={999}
                  position="absolute"
                  alignItems={'center'}
                >
                  <img width={35} src="/images/cards/logo-aviato.png" />

                  <Typography
                    fontSize={28}
                    fontWeight={600}
                    textAlign={'center'}
                  >
                    你好，我是
                  </Typography>

                  <Typography
                    fontSize={28}
                    fontWeight={600}
                    sx={{
                      background:
                        'linear-gradient(270deg,#ae79cc 3%,#1366ec 96%)',
                      backgroundClip: 'text',
                      color: 'transparent',
                      WebkitBackgroundClip: 'text'
                    }}
                    textAlign={'center'}
                  >
                    GLSofort AI助手
                  </Typography>
                </Stack>
              )}
            </MainContainer>

            <Box
              sx={{
                display: 'none'
              }}
            >
              <input
                type="file"
                onChange={async (e) => {
                  if (e.target.files) {
                    setProcess(0)

                    setMessageInputValue(
                      `<br/><br/><br/><br/>${messageInputValue}`
                    )
                    const file = e.target.files[0]

                    setFile(file)
                    const fd = new FormData()
                    const fileName = file.name.replace(/\s+/g, '')

                    fd.append('file', file, encodeURI(fileName))

                    const fileUrl = URL.createObjectURL(file)

                    // 创建一个FileReader实例

                    const reader = new FileReader()

                    // 读取文件内容作为ArrayBuffer

                    reader.readAsArrayBuffer(file)

                    // 监听FileReader的load事件
                    messageRef?.current?.handleClick(
                      t('文件上传中，请稍后'),
                      'info'
                    )

                    reader.onload = async function () {
                      // 当读取完成，result属性将包含一个ArrayBuffer

                      const arrayBuffer = reader.result as ArrayBuffer

                      // 将ArrayBuffer转换为Uint8Array

                      const uint8Array = new Uint8Array(arrayBuffer)

                      // 现在你可以使用uint8Array了，比如将其发送到服务器

                      console.log(uint8Array)

                      const pdfDocument = await pdfjsLib.getDocument(uint8Array)
                        .promise

                      // 获取PDF文档的总页数
                      const numPages = pdfDocument.numPages

                      const pdfAllcontent = [] // 读取每一页的内容

                      for (let i = 1; i <= numPages; i++) {
                        const page = await pdfDocument.getPage(i)
                        const content = await page.getTextContent()
                        const text = content.items
                          .map((item: any) => item.str)
                          .join('')

                        pdfAllcontent.push(`Page ${i}: ${text}`)
                      }

                      console.log(pdfAllcontent)

                      messageRef?.current?.handleClick(
                        t('文件上传完成'),
                        'success'
                      )

                      setProcess(100)

                      setArticle(
                        `请用中文解读一下文献：${pdfAllcontent.join('\n')}`
                      )

                      // const newChat = [
                      //   ...historyMsg,
                      //   {
                      //     message: `
                      //     请解读一下文献：
                      //     ${pdfAllcontent.join('\n')}`,
                      //     sentTime: formatDate(new Date()) ?? '',
                      //     sender: 'user',
                      //     direction: MessageDirection.OUT,
                      //     position: 'last'
                      //   }
                      // ]

                      // const param = {
                      //   messages: {
                      //     messages: newChat
                      //       .filter((item) => item.sender !== 'sys')
                      //       .filter((item) =>
                      //         item.message.includes('请解读一下文献：')
                      //       )
                      //       .map((item) => {
                      //         return {
                      //           role: item.sender,
                      //           content: `${item.message}`
                      //         }
                      //       })
                      //   },
                      //   his: newChat
                      // }

                      // console.log(param)

                      // await getChatClaudes(param)

                      return pdfAllcontent
                    }

                    // 监听FileReader的error事件（可选）

                    reader.onerror = function () {
                      console.error('文件读取失败', reader.error)
                    }

                    // uploadPdf.mutateAsync({ file: fd, url: fileUrl })
                  }
                }}
                ref={inputRef}
              />
            </Box>

            <PositionedSnackbar ref={messageRef} />
          </Box>
        </DialogContent>
      </Drawer>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={false}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          GLSofort {t('AI Assistant')}
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <Box
            sx={{
              position: 'relative',
              height: isWindow() ? '500px' : '400px',
              width: isWindow() ? 500 : '100%'
            }}
          >
            <MainContainer
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                position: 'relative'
              }}
            >
              <ChatContainer>
                <MessageList>
                  {historyMsg.map((item) => {
                    return (
                      <React.Fragment>
                        {item.message.includes('请用中文解读一下文献') ? (
                          <Message
                            model={{
                              message: '解读文献',
                              sentTime: item.sentTime,
                              sender: item.sender,
                              direction: item.direction,
                              position: item.position as 'normal'
                            }}
                            children={
                              <Avatar name="Zoe" src="/images/avatars/ai.png" />
                            }
                          />
                        ) : (
                          <Message
                            model={{
                              message: item.message,
                              sentTime: item.sentTime,
                              sender: item.sender,
                              direction: item.direction,
                              position: item.position as 'normal'
                            }}
                            children={
                              item.direction === MessageDirection.IN ? (
                                <Avatar
                                  name="Zoe"
                                  src="/images/avatars/ai.png"
                                />
                              ) : (
                                <Avatar
                                  name="Zoe"
                                  src="/images/avatars/3.png"
                                />
                              )
                            }
                          />
                        )}

                        {item.direction === MessageDirection.IN ? (
                          <Box maxWidth={'85%'} textAlign="right">
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                //
                                try {
                                  if (item.message) {
                                    copyToClipboard(item.message, e)

                                    messageRef?.current?.handleClick(
                                      t('Successful copy'),
                                      'success'
                                    )
                                  }
                                } catch (error) {
                                  console.log(123, error)
                                }
                              }}
                            >
                              <ContentCopy />
                            </IconButton>
                          </Box>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    )
                  })}
                </MessageList>

                <MessageInput
                  placeholder={t(
                    '请将您遇到的问题告诉我，使用 Shift + Enter 换行'
                  )}
                  onSend={handleNewUserMessage}
                  onAttachClick={(e) => {
                    inputRef.current?.click()
                  }}
                  ref={messageInputRef}
                  onChange={(
                    innerHtml: string,
                    textContent: string,
                    innerText: string,
                    nodes: NodeList
                  ) => {
                    console.log(innerHtml, textContent, innerText)

                    setMessageInputValue(innerHtml)
                  }}
                  value={messageInputValue}
                />
              </ChatContainer>

              {file ? (
                <Box
                  id={'125'}
                  sx={{
                    position: 'absolute',
                    zIndex: 999,
                    width: '80%',
                    right: 0,
                    left: 0,
                    margin: '0 auto',
                    bottom: '35px'
                  }}
                  maxWidth={300}
                >
                  <Card>
                    <CardContent
                      sx={{
                        p: 2,
                        paddingBottom: '0.5rem !important'
                      }}
                    >
                      <Grid container>
                        <Grid item>
                          <Box
                            sx={{
                              bgcolor: 'rgb(255,86,135)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 50,
                              height: 50,
                              borderRadius: '5px'
                            }}
                          >
                            <FacebookCircularProgress
                              value={process}
                              variant={'determinate'}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={9} pl={2}>
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              width: '100%',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {file?.name}
                          </Typography>

                          <Stack
                            direction={'row'}
                            alignItems="center"
                            justifyContent={'space-between'}
                          >
                            <Typography>PDF</Typography>

                            <IconButton
                              onClick={() => {
                                setProcess(0)

                                setArticle('')

                                setFile(null)

                                setMessageInputValue('')
                              }}
                              size="small"
                            >
                              <Close />
                            </IconButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                ''
              )}

              {historyMsg && historyMsg.length ? (
                ''
              ) : (
                <Stack
                  justifyContent={'center'}
                  sx={{
                    left: 0,
                    right: 0,
                    top: '30%',
                    margin: '0 auto'
                  }}
                  zIndex={999}
                  position="absolute"
                  alignItems={'center'}
                >
                  <img width={35} src="/images/cards/logo-aviato.png" />

                  <Typography
                    fontSize={28}
                    fontWeight={600}
                    textAlign={'center'}
                  >
                    你好，我是
                  </Typography>

                  <Typography
                    fontSize={28}
                    fontWeight={600}
                    sx={{
                      background:
                        'linear-gradient(270deg,#ae79cc 3%,#1366ec 96%)',
                      backgroundClip: 'text',
                      color: 'transparent',
                      WebkitBackgroundClip: 'text'
                    }}
                    textAlign={'center'}
                  >
                    GLSofort AI助手
                  </Typography>
                </Stack>
              )}
            </MainContainer>

            <Box
              sx={{
                display: 'none'
              }}
            >
              <input
                type="file"
                onChange={async (e) => {
                  if (e.target.files) {
                    setProcess(0)

                    setMessageInputValue(
                      `<br/><br/><br/><br/>${messageInputValue}`
                    )
                    const file = e.target.files[0]

                    setFile(file)
                    const fd = new FormData()
                    const fileName = file.name.replace(/\s+/g, '')

                    fd.append('file', file, encodeURI(fileName))

                    const fileUrl = URL.createObjectURL(file)

                    // 创建一个FileReader实例

                    const reader = new FileReader()

                    // 读取文件内容作为ArrayBuffer

                    reader.readAsArrayBuffer(file)

                    // 监听FileReader的load事件
                    messageRef?.current?.handleClick(
                      t('文件上传中，请稍后'),
                      'info'
                    )

                    reader.onload = async function () {
                      // 当读取完成，result属性将包含一个ArrayBuffer

                      const arrayBuffer = reader.result as ArrayBuffer

                      // 将ArrayBuffer转换为Uint8Array

                      const uint8Array = new Uint8Array(arrayBuffer)

                      // 现在你可以使用uint8Array了，比如将其发送到服务器

                      console.log(uint8Array)

                      const pdfDocument = await pdfjsLib.getDocument(uint8Array)
                        .promise

                      // 获取PDF文档的总页数
                      const numPages = pdfDocument.numPages

                      const pdfAllcontent = [] // 读取每一页的内容

                      for (let i = 1; i <= numPages; i++) {
                        const page = await pdfDocument.getPage(i)
                        const content = await page.getTextContent()
                        const text = content.items
                          .map((item: any) => item.str)
                          .join('')

                        pdfAllcontent.push(`Page ${i}: ${text}`)
                      }

                      console.log(pdfAllcontent)

                      messageRef?.current?.handleClick(
                        t('文件上传完成'),
                        'success'
                      )

                      setProcess(100)

                      setArticle(
                        `请用中文解读一下文献：${pdfAllcontent.join('\n')}`
                      )

                      // const newChat = [
                      //   ...historyMsg,
                      //   {
                      //     message: `
                      //     请解读一下文献：
                      //     ${pdfAllcontent.join('\n')}`,
                      //     sentTime: formatDate(new Date()) ?? '',
                      //     sender: 'user',
                      //     direction: MessageDirection.OUT,
                      //     position: 'last'
                      //   }
                      // ]

                      // const param = {
                      //   messages: {
                      //     messages: newChat
                      //       .filter((item) => item.sender !== 'sys')
                      //       .filter((item) =>
                      //         item.message.includes('请解读一下文献：')
                      //       )
                      //       .map((item) => {
                      //         return {
                      //           role: item.sender,
                      //           content: `${item.message}`
                      //         }
                      //       })
                      //   },
                      //   his: newChat
                      // }

                      // console.log(param)

                      // await getChatClaudes(param)

                      return pdfAllcontent
                    }

                    // 监听FileReader的error事件（可选）

                    reader.onerror = function () {
                      console.error('文件读取失败', reader.error)
                    }

                    // uploadPdf.mutateAsync({ file: fd, url: fileUrl })
                  }
                }}
                ref={inputRef}
              />
            </Box>

            <PositionedSnackbar ref={messageRef} />
          </Box>
        </DialogContent>

        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </Box>
  )
})

export default ChatClaude
