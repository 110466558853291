import moment from 'moment'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

import { useReactToPrint } from 'react-to-print'

import { jsx } from 'slate-hyperscript'

const TIME_FORMAT = {
  timeFormat: `YYYY-MM-DD HH:mm:ss`,
  timeFormat2: `YYYY-MM-DD HH:mm`,
  timeFormat3: `YYYYMMDDHHmmss`
}

/**
 * 日期格式化
 * @param date 日期 |字符串 |时间戳
 * @param format 时间格式  默认：YYYY-MM-DD HH:mm:ss
 * @returns {string|null}
 */
export function formatDate(
  date: moment.MomentInput,
  format = TIME_FORMAT.timeFormat
) {
  if (!date) return null

  return moment(date).format(format)
}

export function filterObjectsByTextWithRemainder(
  array: any[],
  searchString: string
) {
  const sliceIndex = array.findIndex((item) =>
    item.some((i: any) => i.text && i.text.includes(searchString))
  )

  console.log('sliceIndex', sliceIndex)

  return sliceIndex < 0 ? array : array.slice(0, sliceIndex)
}

/**
 * 日期格式化
 * @param chromosomeNum chrom
 * @param startPos
 * @param endPos
 * @param chromosomeData
 * @returns {string|null}
 */
export function chromosomeRegion(
  chromosomeNum: any,
  startPos: number,
  endPos: number,
  chromosomeData: any[]
) {
  if (!chromosomeData) {
    return 'Unknown chromosome'
  }

  let startRegion = ''
  let endRegion = ''

  chromosomeData.map((item) => {
    const { chromStart, chromEnd, name } = item

    if (startPos >= chromStart && startPos <= chromEnd) {
      startRegion = name
    }

    if (endPos >= chromStart && endPos <= chromEnd) {
      endRegion = name
    }
  })

  if (startRegion === endRegion) {
    return startRegion
  } else {
    return `${startRegion}-${endRegion}`
  }
}

/**
 * 根据年龄计算出生日期
 * @param age 年龄
 * @returns {Date|null}
 */
export function calculateBirthDate(age: number) {
  // 获取当前日期

  const now = new Date()

  // 计算出生年份

  const birthYear = now.getFullYear() - age

  // 设置出生日期的月份和日期为当前月份和日期

  // 注意：这可能导致问题，因为不是每个月都有相同的天数

  // 或者闰年的2月有29天，而非闰年只有28天

  const birthDate = new Date(birthYear, now.getMonth(), now.getDate())

  // 如果计算出的日期在当前年份之后（由于月份或日期的减少），则需要修正

  if (birthDate > now) {
    // 如果月份为0（即1月），则需要减少年份并设置月份为11（即12月）

    if (birthDate.getMonth() === 0) {
      birthDate.setFullYear(birthYear - 1)

      birthDate.setMonth(11) // 11代表12月
    } else {
      birthDate.setMonth(birthDate.getMonth() - 1)
    }

    // 尝试将日期设置为当前日期的日期（可能无效，如果那个月没有那么多天）

    birthDate.setDate(now.getDate())

    // 如果日期仍然无效（即大于那个月的最大天数），则设置为那个月的最后一天

    if (birthDate > now) {
      birthDate.setDate(0) // 设置为那个月的最后一天
    }
  }

  // 返回出生日期（可能是一个近似的日期，因为月份和日期的减少可能不总是精确的）

  return birthDate
}

/**
 * 判断字符串是否为url
 * @param str url
 * @returns {boolean}
 */
export function isValidURL(str: string) {
  try {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator

    return !!pattern.test(str)
  } catch (error) {
    return true
  }
}

/**
 * 对象数组去重
 * @param arr 去重数组
 * @param key 去重key
 * @returns {Array}
 */
export function unique(arr: any[], key: string | number) {
  const res = new Map()

  return arr.filter((arr) => !res.has(arr[key]) && res.set(arr[key], 1))
}

/**
 * 转化为数字字符串
 * @param input 字符串
 * @returns {string}
 */
export function addCommas(input: string) {
  // 检查输入是否为有效的数字字符串（可以包含小数点）

  const isNumeric = /^\d+(\.\d+)?$/.test(input)

  if (!isNumeric) {
    return input
  }

  const parts = input.split('.')

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

/**
 * 判断是否为电脑端网页
 * @returns {boolean}
 */
export function isWindow() {
  let type = 'window'

  if (/(micromessenger)/i.test(navigator.userAgent)) {
    // alert('微信')
    type = 'mobile'
  } else {
    // alert('普通浏览器')

    // 判断h5还是pc true就是h5
    const client =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    if (client) {
      // alert('h5')
      type = 'mobile'
    } else {
      // alert('pc')
      type = 'window'
    }
  }

  return type === 'window' ? true : false
}

/**
 * 判断是否为json
 * @param key 字符串
 * @returns {Boolean}
 */
export function isJson(key: string) {
  try {
    JSON.parse(key)

    return true
  } catch (error) {
    return false
  }
}

/**
 * 函数防抖
 * @param func fn
 * @param wait 时间
 * @returns {fn}
 */
export function debounce(func: any, wait: number) {
  let timeout = null as any

  return function (...args: any) {
    const later = () => {
      clearTimeout(timeout)

      // @ts-ignore: Implicit 'any' type because no type annotation is present.
      func.apply(this as any, args)
    }

    clearTimeout(timeout)

    timeout = setTimeout(later, wait)
  }
}

/**
 * 导出为PDF
 * @param id 节点id
 * @returns {null}
 */
export function PrintHtmlToPDF(dom: HTMLElement, name = '') {
  // const dom = document.getElementById(id) as any //获取需要打印的区域

  html2canvas(dom).then((canvas) => {
    const contentWidth = canvas.width
    const contentHeight = canvas.height

    //一页pdf显示html页面生成的canvas高度;
    const pageHeight = (contentWidth / 592.28) * 841.89

    //未生成pdf的html页面高度
    let leftHeight = contentHeight

    //页面偏移
    let position = 0

    //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    const imgWidth = 595.28
    const imgHeight = (592.28 / contentWidth) * contentHeight

    const pageData = canvas.toDataURL('image/jpeg', 1.0)

    const pdf = new jsPDF(undefined, 'pt', 'a4')

    //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
    //当内容未超过pdf一页显示的范围，无需分页

    if (leftHeight < pageHeight) {
      pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      while (leftHeight > 0) {
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)

        leftHeight -= pageHeight

        position -= 841.89

        //避免添加空白页
        if (leftHeight > 0) {
          pdf.addPage()
        }
      }
    }

    pdf.save(`${name}.pdf`)
  })
}

/**
 * 导出为PDF
 * @param id 节点id
 * @returns {null}
 */
export function PrintReactHtmlToPDF(
  printRef: React.MutableRefObject<any>,
  name = ''
) {
  // 打印功能
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
        @page {
          size: A4;
          margin: 0;
        }
        @media print {
          body, html {
            height: auto;
            margin: 0;
          }
          body{
            zoom: 100%;
          }
          img{
            max-width: 100% !important;
            height: auto !important;
            page-break-inside: auto;
            break-inside: auto;
          }
        }`,
    removeAfterPrint: true,
    documentTitle: `${name}.pdf`
  })

  handlePrint()
}

/**
 * 下载图片地址
 * @param imageUrl  图片地址imageUrl
 * @param name  下载图片文件名
 * @returns {null}
 */
export async function DownloadImage(imageUrl: string, name: string) {
  if (!imageUrl) {
    console.error('No image URL provided')

    return
  }

  try {
    const response = await fetch(imageUrl)

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const blob = await response.blob()

    // 创建一个指向blob对象的URL

    const url = window.URL.createObjectURL(blob)

    // 创建一个临时的a标签用于下载

    const link = document.createElement('a')

    link.href = url

    link.setAttribute('download', name + '.png') // 设置下载文件的名称

    document.body.appendChild(link)

    link.click()

    // 清理

    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Failed to download image:', error)
  }
}

/**
 * 查找并返回第一个连续的数字序列
 * @param str  str
 * @returns {null | number}
 */
export async function extractFirstNumber(str: string) {
  // 使用正则表达式匹配第一个数字序列

  // \d+ 表示匹配一个或多个数字

  // ^ 表示匹配字符串的开始（但在这里我们不使用它，因为我们想在字符串中的任何位置查找）

  // \D* 表示匹配任意数量的非数字字符（前面的数字之前的）

  // \b 表示单词边界，确保我们匹配的是完整的单词（数字序列）

  const match = str.match(/\b\d+\b/)

  // 如果找到了匹配项，则返回匹配的数字字符串

  // 否则返回null或undefined（取决于你希望如何处理没有找到的情况）

  return match ? match[0] : null
}

interface NoObject {
  [key: string]: any
}

export function demo() {
  const NewElement = {
    ...Element,
    serializeWithStyles: null
  } as any

  NewElement.prototype = Element.prototype

  NewElement.prototype['serializeWithStyles'] = (function () {
    // Mapping between tag names and css default values lookup tables. This allows to exclude default values in the result.

    const defaultStylesByTagName: NoObject = {}

    // Styles inherited from style sheets will not be rendered for elements with these tag names

    const noStyleTags: NoObject = {
      BASE: true,
      HEAD: true,
      HTML: true,
      META: true,
      NOFRAME: true,
      NOSCRIPT: true,
      PARAM: true,
      SCRIPT: true,
      STYLE: true,
      TITLE: true
    }

    const tagNames = [
      'A',
      'ABBR',
      'ADDRESS',
      'AREA',
      'ARTICLE',
      'ASIDE',
      'AUDIO',
      'B',
      'BASE',
      'BDI',
      'BDO',
      'BLOCKQUOTE',
      'BODY',
      'BR',
      'BUTTON',
      'CANVAS',
      'CAPTION',
      'CENTER',
      'CITE',
      'CODE',
      'COL',
      'COLGROUP',
      'COMMAND',
      'DATALIST',
      'DD',
      'DEL',
      'DETAILS',
      'DFN',
      'DIV',
      'DL',
      'DT',
      'EM',
      'EMBED',
      'FIELDSET',
      'FIGCAPTION',
      'FIGURE',
      'FONT',
      'FOOTER',
      'FORM',
      'H1',
      'H2',
      'H3',
      'H4',
      'H5',
      'H6',
      'HEAD',
      'HEADER',
      'HGROUP',
      'HR',
      'HTML',
      'I',
      'IFRAME',
      'IMG',
      'INPUT',
      'INS',
      'KBD',
      'KEYGEN',
      'LABEL',
      'LEGEND',
      'LI',
      'LINK',
      'MAP',
      'MARK',
      'MATH',
      'MENU',
      'META',
      'METER',
      'NAV',
      'NOBR',
      'NOSCRIPT',
      'OBJECT',
      'OL',
      'OPTION',
      'OPTGROUP',
      'OUTPUT',
      'P',
      'PARAM',
      'PRE',
      'PROGRESS',
      'Q',
      'RP',
      'RT',
      'RUBY',
      'S',
      'SAMP',
      'SCRIPT',
      'SECTION',
      'SELECT',
      'SMALL',
      'SOURCE',
      'SPAN',
      'STRONG',
      'STYLE',
      'SUB',
      'SUMMARY',
      'SUP',
      'SVG',
      'TABLE',
      'TBODY',
      'TD',
      'TEXTAREA',
      'TFOOT',
      'TH',
      'THEAD',
      'TIME',
      'TITLE',
      'TR',
      'TRACK',
      'U',
      'UL',
      'VAR',
      'VIDEO',
      'WBR'
    ]

    // Precompute the lookup tables.

    for (let i = 0; i < tagNames.length; i++) {
      if (!noStyleTags[tagNames[i]]) {
        defaultStylesByTagName[tagNames[i]] = computeDefaultStyleByTagName(
          tagNames[i]
        )
      }
    }

    function computeDefaultStyleByTagName(tagName: string) {
      const defaultStyle: NoObject = {}

      const element = document.body.appendChild(document.createElement(tagName))

      const computedStyle: any = getComputedStyle(element)

      for (let i = 0; i < computedStyle.length; i++) {
        defaultStyle[computedStyle[i]] = computedStyle[computedStyle[i]]
      }

      document.body.removeChild(element)

      return defaultStyle
    }

    function getDefaultStyleByTagName(tagName: string) {
      tagName = tagName.toUpperCase()

      if (!defaultStylesByTagName[tagName]) {
        defaultStylesByTagName[tagName] = computeDefaultStyleByTagName(tagName)
      }

      return defaultStylesByTagName[tagName]
    }

    return function serializeWithStyles(this: any) {
      if (this.nodeType !== Node.ELEMENT_NODE) {
        throw new TypeError()
      }

      const cssTexts = []

      const elements = this.querySelectorAll('*')

      for (let i = 0; i < elements.length; i++) {
        const e = elements[i]

        if (!noStyleTags[e.tagName]) {
          const computedStyle: any = getComputedStyle(e)

          const defaultStyle = getDefaultStyleByTagName(e.tagName)

          cssTexts[i] = e.style.cssText

          for (let ii = 0; ii < computedStyle.length; ii++) {
            const cssPropName = computedStyle[ii]

            if (computedStyle[cssPropName] !== defaultStyle[cssPropName]) {
              e.style[cssPropName] = computedStyle[cssPropName]
            }
          }
        }
      }

      const result = this.outerHTML

      for (let i = 0; i < elements.length; i++) {
        elements[i].style.cssText = cssTexts[i]

        const e = elements[i]
      }

      return result
    }
  })()
}

const ELEMENT_TAGS: NoObject = {
  A: (el: any) => ({ type: 'link', url: el.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  ARTICLE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading-one' }),
  H2: () => ({ type: 'heading-two' }),
  H3: () => ({ type: 'heading-three' }),
  H4: () => ({ type: 'heading-four' }),
  H5: () => ({ type: 'heading-five' }),
  H6: () => ({ type: 'heading-six' }),
  IMG: (el: any) => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  TABLE: () => ({ type: 'table' }),
  TR: () => ({ type: 'table-row' }),
  TD: () => ({ type: 'table-cell' }),
  UL: () => ({ type: 'bulleted-list' })
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS: NoObject = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true })
}

export function deserialize(el: any) {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const { nodeName } = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children: any = Array.from(parent.childNodes).map(deserialize).flat()

  if (children.length === 0) {
    children = [{ text: '' }]
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)

    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el)

    console.log(attrs)

    return children.map((child: any) => jsx('text', attrs, child))
  }

  return children
}

export enum SpecialInheritance {
  AR = 'autosomal recessive',
  AD = 'autosomal dominant',
  XD = 'x-linked dominant',
  XR = 'x-linked recessive',
  X = 'x-linked'
}

export enum SpecialInheritanceShortNotation {
  AR = 'AR',
  AD = 'AD',
  XD = 'X-D',
  XR = 'X-R',
  X = 'X-linked'
}

export function formatVariantInheritance(inheritance: string) {
  const specialInheritances = Object.values(SpecialInheritance)
  const specialInheritanceShorts = Object.values(
    SpecialInheritanceShortNotation
  )

  const result: SpecialInheritanceShortNotation[] = []

  specialInheritances.forEach((e, i) => {
    if (typeof inheritance === 'string' && inheritance.includes(e)) {
      result.push(specialInheritanceShorts[i])
    }
  })

  return result.join(', ')
}
