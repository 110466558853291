// ** Next Imports
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { Router } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from 'react-query'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import themeConfig from 'src/configs/themeConfig'
import 'src/configs/i18n'
import { defaultACLObj } from 'src/configs/acl'
import AclGuard from 'src/@core/components/auth/AclGuard'

// ** Component Imports
import UserLayout from 'src/layouts/UserLayout'

// import ThemeComponent from 'src/@core/theme/ThemeComponent'

// ** Contexts
import {
  SettingsConsumer,
  SettingsProvider
} from 'src/@core/context/settingsContext'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Global css styles
import '../../styles/globals.css'
import '../@core/components/tanstack-tables/test.css'
import '../@core/components/tanstack-tables/table.css'
import { AuthProvider } from '@/context/AuthContext'
import { useEffect } from 'react'
import { isWindow } from '@/@core/utils/utils'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

// react-query配置
const defaultOptions = {
  queries: {
    /*
    staleTime 重新获取数据的时间间隔 默认0 , Infinity 永不过期
    cacheTime 数据缓存时间 默认 1000 * 60 * 5 5分钟
    retry 失败重试次数 默认 3次
    refetchOnWindowFocus 窗口重新获得焦点时重新获取数据 默认 false
    refetchOnReconnect 网络重新链接
    refetchOnMount 实例重新挂载
    enabled 如果为“false”的化，“useQuery”不会触发，需要使用其返回的“refetch”来触发操作
    */
    retry: 0, // 失败重试次数
    refetchOnWindowFocus: false, // 窗口重新获得焦点时重新获取数据
    refetchOnReconnect: false // 网络重新链接时重新获取数据
  } //查询配置
  //mutations:{} 用来更新的配置
}
const queryClient = new QueryClient({
  defaultOptions
}) //默认QueryClient可以为空

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })

  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })

  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

// 监听路由变化

Router.events.on('routeChangeStart', () => {
  // 这里可以添加逻辑来取消所有查询
  console.log('--------')

  queryClient.cancelQueries()
})

const ThemeComponent = dynamic(
  async () => {
    const mod = await import('src/@core/theme/ThemeComponent')

    return mod.default
  },
  { ssr: false }
)

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const getLayout =
    Component.getLayout ?? ((page) => <UserLayout>{page}</UserLayout>)

  const authGuard = Component.authGuard ?? false

  const guestGuard = Component.guestGuard ?? false

  const aclAbilities = Component.acl ?? defaultACLObj

  useEffect(() => {
    if (!isWindow()) document.documentElement.style.fontSize = '14px'
    else document.documentElement.style.fontSize = '16px'
  })

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>{`${themeConfig.templateName}`}</title>

            <meta
              name="description"
              content={`${themeConfig.templateName} – `}
            />

            <meta name="keywords" content="" />

            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>

          <AuthProvider>
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeComponent settings={settings}>
                      {/* {getLayout(<Component {...pageProps} />)} */}
                      <AclGuard
                        aclAbilities={aclAbilities}
                        guestGuard={guestGuard}
                        authGuard={authGuard}
                      >
                        {getLayout(<Component {...pageProps} />)}
                      </AclGuard>
                    </ThemeComponent>
                  )
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </AuthProvider>
        </CacheProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App
